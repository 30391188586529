import { initApp } from "./app/init";
import { completeSignIn } from "./auth/sign-in";
import { NhsAuthenticationError } from "./nhs/nhs-authentication-error";
import { StateWithLocationState } from "./router/state";
import { getConfig } from "./runtime-config";
import { isPublicPathname } from "./urls";
import { getUser } from "./user/app-user";
import { userManager } from "./user-manager";
import { logError } from "./utils";

const isEndSessionRoute =
  globalThis.location.pathname === "/logout/end-session";
const isLoginRoute = globalThis.location.pathname === "/login";
const isPublicRoute = isPublicPathname(globalThis.location.pathname);
const isSilentLoginRoute = globalThis.location.pathname === "/login/silent";
const isLogoutRoute = globalThis.location.pathname === "/logout";
const isLogoutCallbackRoute =
  globalThis.location.pathname === "/logout/callback";

const { WEBSITE_URL } = getConfig();

const referrerKey = "referrer";

function redirectBackToReferrer() {
  const url = globalThis.localStorage.getItem(referrerKey) ?? WEBSITE_URL;
  globalThis.localStorage.removeItem(referrerKey);
  globalThis.location.replace(url);
}

void (async function () {
  if (isPublicRoute) {
    await userManager.clearStaleState();
    const user = await getUser();
    initApp({ user });
    return;
  }

  const referrer = globalThis.document.referrer;

  if (referrer.includes("waitcure.com") || referrer.includes("zymego.com")) {
    globalThis.localStorage.setItem(referrerKey, referrer);
  }

  if (globalThis.location.href.includes("hostname")) {
    const searchParameters = new URLSearchParams(globalThis.location.search);
    const clinicName = searchParameters.get("hostname")?.split(".").slice(0, 1);
    if (clinicName) {
      globalThis.location.replace(`bookings/new?clinicName=${clinicName}`);
    }
    return;
  }

  if (isEndSessionRoute) {
    await userManager.clearStaleState();
    redirectBackToReferrer();
    return;
  }

  await userManager.clearStaleState();
  let user = await getUser();

  if (isLogoutRoute) {
    userManager.signoutRedirect();
    return;
  }

  if (isLogoutCallbackRoute) {
    userManager.signoutCallback();
    redirectBackToReferrer();
    return;
  }

  if (isLoginRoute || isSilentLoginRoute) {
    try {
      user = await completeSignIn();

      if (isSilentLoginRoute) {
        return;
      }
    } catch (error) {
      logError(error);

      if (error instanceof NhsAuthenticationError) {
        initApp({ state: error.userState, user: undefined });
        return;
      } else {
        if (
          typeof error === "object" &&
          error &&
          (("error" in error && error.error === "IDP-3200") ||
            ("state" in error && error.state === "retry"))
        ) {
          // TODO: We should probably render/redirect to some error view instead of just redirecting to the website.
          await userManager.clearStaleState();
          redirectBackToReferrer();
          return;
        }

        void userManager.signinRedirect({ state: "retry" });
        return;
      }
    }
  }

  if (user) {
    initApp({ user });

    const userState = user.state;
    const locationState = userState?.locationState;

    if (locationState) {
      globalThis.history.replaceState(
        locationState.state,
        "",
        `${locationState.pathname}${locationState.search ?? ""}`,
      );
    }
  } else {
    const state: StateWithLocationState = {
      locationState: {
        pathname: globalThis.location.pathname,
        search: globalThis.location.search,
        state: globalThis.history.state,
      },
    };
    void userManager.signinRedirect({ state });
  }
})();
